<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-6">
        <h1>Update Leave Request for {{ leaveRequest.personnel.first_name }} {{ leaveRequest.personnel.last_name }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-form @submit.prevent="updateLeaveRequest">
          <v-row class="pt-8">
            <v-col class="col-3">
              <label>Start Date</label>
              <v-menu
                  v-model="menuStart"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      dense
                      v-model="leaveRequest.first_day"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="startDate"
                    @input="menuStart = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="col-3">
              <label>End Date</label>
              <v-menu
                  v-model="menuEnd"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      dense
                      v-model="leaveRequest.last_day"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="endDate"
                    @input="menuEnd = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="col-3">
              <label>Coverage</label>
              <v-select
                dense
                :items="coverageOptions"
                v-model="leaveRequest.coverage"></v-select>
            </v-col>
            <v-col class="col-3">
              <label>Total days</label>
              <v-text-field
                dense
                type="number"
                step="0.01"
                v-model="leaveRequest.total_days"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="col-12 text-right">
              <v-btn type="submit">Save Changes</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "@/axios";

export default {
  name: 'EditLeaveRequest',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      coverageOptions: [
        'Full day',
        'Half day',
      ],
      leaveRequest: {
        coverage: '',
        first_day: '',
        last_day: '',
        personnel: {
          id: 0,
          first_name: '',
          last_name: '',
        },
        total_days: 0,
      },
      menuStart: false,
      menuEnd: false,
      startDate: '',
      endDate: '',
    };
  },
  methods: {
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    getRequest() {
      const id = this.$route.params.id;
      axios.get(`/leaveRequests/getOne/${id}.json?token=${this.token}`)
          .then((response) => {
            this.leaveRequest = response.data.leaveRequest;
            this.leaveRequest.first_day = this.formatDate(response.data.leaveRequest.first_day);
            this.leaveRequest.last_day = this.formatDate(response.data.leaveRequest.last_day);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    updateLeaveRequest() {
      const postData = {
        leaveRequest: this.leaveRequest,
      };
      axios.post(`/leaveRequests/update.json?token=${this.token}`, postData)
          .then((response) => {
            this.leaveRequest = response.data.leaveRequest;
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },
  watch: {
    startDate () {
      this.leaveRequest.first_day = this.formatDate(this.startDate);
    },
    endDate () {
      this.leaveRequest.last_day = this.formatDate(this.endDate);
    },
  },
  mounted() {
    this.getRequest();
  },
};
</script>
